import { addons } from '@storybook/manager-api';
import stardustTheme from './StardustTheme'

addons.setConfig({
  sidebar: {
    showRoots: false,

  },
  theme: stardustTheme,
  
});